import React , { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select, Popconfirm, Checkbox} from 'antd';
import SubscriptionModalWrapper from './SubscriptionModal.styles';
import IntlMessages from '@iso/components/utility/intlMessages';
import Stripe from '@iso/containers/Pages/Stripe/Stripe';
import { subscriptionService } from '@iso/services';
import _ from 'lodash';
import stripeActions from '@iso/redux/stripe/actions';
import {useDispatch,useSelector } from 'react-redux';
import { subscriptionPlan } from '@iso/constants/subscriptionPlan';
import subscriptionActions from '@iso/redux/subscription/actions';
import logo from '@iso/assets/images/stripe.png';

const { Option } = Select;
const {triggerStripe} = stripeActions;
const { changeActivePropertySubscription } = subscriptionActions;

const SubscriptionModal = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCancelSub, setLoadingCancelSub] = useState(false);
  const [checkingError, setCheckingError] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const {property} = props;
  const [fields, setFields] = useState([]);

  useEffect(() => {
    //TODO: load data here
    setShowForm(true);
    setSubscriptions(subscriptionService.getList());
  }, [props]);

  useEffect(() => {
    setFields([
      {
        name: 'plan',
        value: property.subscription_label || process.env.REACT_APP_STRIPE_PLAN_BRONZE,
      }
    ]);
  }, [props.visible]);

  const renderOptionsSubscription = () => {
    let options = [];
    _.forEach(subscriptions, (subscription) => {
      options.push(
        <Option key={subscription.key} value={subscription.price}>
          <IntlMessages id={"subscription.prices." + subscription.key} />
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a plan"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  const cancelModal = () => {
    if (props.visible) {
      props.cancelModal();
    }
    if (props.openSubscription) {
      props.setOpenSubscription(false);
    }  
    setLoading(false);
    form.resetFields();
    setCheckingError(true);
  }

  const handleSubmit = (e) => {
    setLoading(true);
    form
      .validateFields()
      .then(values => {
        setModalLoading(true);
        values.property_id = property.id;
        if(!property.subscription_id){
          dispatch(triggerStripe());          
        }else{
          onStore(values);
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
        setLoading(false);
      });
    setLoading(false);
  }

  const stripeToken = useSelector((state) => state.stripe.token);
  const cardToken = useSelector((state) => state.stripe.cardToken);
  const stripeTrigger = useSelector((state) => state.stripe.stripeTrigger);

  useEffect(() => {
    //Stripe token receive
    if(cardToken){
      form
        .validateFields()
        .then( values => {
          //values.token = stripeToken;
          values.card_token = cardToken;
          values.property_id = property.id;
          onStore(values);
        });
    }
  }, [cardToken]);

  useEffect (() => {
    if(!stripeTrigger && typeof(stripeTrigger) !== 'undefined'){
      setLoading(false);
    }
  }, [stripeTrigger]);

  const onStore = (values) => {
    const data = {
      "subscription":{
        ...values,
        term: values.term && values.term == true ? 1 : 0,
      }
    }
    subscriptionService.storeOrUpdate(data, property.subscription_id).then(res => {
      if(res.code === '0000'){
        props.onSaved();
        if(activePropertyId == res.property.id){
          dispatch(changeActivePropertySubscription(res.property.subscription_label));
        }
        
        props.cancelModal();
        form.resetFields();
        setLoading(false);
        setCheckingError(true);
      }else{
        setLoading(false);
        setCheckingError(true);
      }
    })
    .catch(info => {
        setCheckingError(false);
        setLoading(false);
    });;
  }

  const handleCancelSubscription = (object) => {
    setLoadingCancelSub(true);
    subscriptionService.destroy(object.subscription_id).then(res => {
      if(res.code === '0000'){
        props.onSaved();
        props.cancelModal();
        setLoadingCancelSub(false);
      }
      setLoadingCancelSub(false);
    });
  }

  return(
    <SubscriptionModalWrapper 
    visible={props.openSubscription ? props.openSubscription : props.visible}
    forceRender={true}
    title={<IntlMessages id="antTable.title.subscription" />}
    onCancel={cancelModal}
    footer={[
      (property.subscription_id && 
        <Popconfirm
          placement="bottomRight"
          title={<IntlMessages id="subscription.modal.confirm.cancelSubscription" />}
          onConfirm={handleCancelSubscription.bind(this, property)}
          okText="Yes"
          cancelText="No"
          className="delete-button"
        >
        <Button key="delete" type="danger" loading={loadingCancelSub} style={{height:'40px'}} >
          {<IntlMessages id="subscription.modal.cancelSubscription" />}
        </Button>
        </Popconfirm>),
      <Button key="back" onClick={cancelModal} className="cancel-button">
        {<IntlMessages id="propertyPage.modal.cancel" />}
      </Button>,
      <Button key="submit" className="save-button" type="primary" loading={loading} onClick={handleSubmit}>
        {<IntlMessages id="propertyPage.modal.save" />}
      </Button>,
    ]}
    >
      <Form style={{padding:'20px'}}
        form={form}        
        name="subscriptionForm"
        layout="vertical"
        scrollToFirstError
        fields={fields}
      >
        {
          showForm &&
          <>
            <div className="form-body">
              <Form.Item
                name="plan"
                label="Plan"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
              >
                {renderOptionsSubscription()}
              </Form.Item>
              <Form.Item
                name="coupon"
                label="Coupon"
              >
                <Input size="large" placeholder="Input coupon"/>
              </Form.Item>
              {!property.subscription_id && (<><Form.Item
                name="payment"
                label="Payment"         
              >
              <img src={logo} style={{width:"70%"}}/>
              <br/>
              <br/>
              <Stripe />
              </Form.Item>
              <br/>
              <Form.Item
                name="term"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: 'Please check if you agree with our terms',
                  },
                ]}
              >
              <Checkbox >{<IntlMessages id="propertyPage.subscriptionModal.termAndCondition" />}
              <a href="http://airagri.com.au/index.php/termsconditions/" target="_blank">Terms & Conditions</a>
              </Checkbox>
              </Form.Item></>
              )}
              { (!checkingError) ? <div className="ant-form-item-has-error"><div className="ant-form-item-explain"><div>{<IntlMessages id="subscription.modal.error" />}</div></div></div> : ''}
              <div>
                <p>Payment (Credit card will not be charged if active coupon is entered)</p>
                <p>If you would like us to manually assist with the upgrade please email</p>
                <a href="mailto:support@airagri.com.au">support@airagri.com.au</a>
              </div>
            </div>
          </>
        }  
      </Form>      
    </SubscriptionModalWrapper>
  ); 
}

export default SubscriptionModal;